import React, { useEffect, useMemo, useState } from "react";
import { LineChart } from "react-chartkick";
import { Card, CardText, CardTitle, Col, Row } from "reactstrap";
import NumberField from "../Controls/NumberField";
import axiosInstance from "../misc/Axios";
import "./Dashboard.scss";

export default function Dashboard() {

  const [state, setState] = useState({
    loading: false,
    live_products_count: 0,
    sales_trend: [],
    orders_trend: [],
    pending_products_count: 0,
    total_products: 0,
    orders_pending_confirmation: 0,
    active_vendors_count: 0,
    inactive_vendors_count: 0,
    active_products_count: 0,
    inactive_products_count: 0,
  })

  useEffect(() => {
    setState({
      ...state,
      loading: true,
    });
    const timeOut = setTimeout(() => {
      Promise.all([
        axiosInstance.get("/admins/dashboard/live_products_count"),
        axiosInstance.get("/admins/dashboard/pending_products_count"),
        axiosInstance.get("/admins/dashboard/total_products_count"),
        axiosInstance.get(
          "/admins/dashboard/orders_pending_confirmation_count"
        ),
        axiosInstance.get("/admins/dashboard/live_company_count"),
        axiosInstance.get("/admins/dashboard/inactive_company_count"),
        axiosInstance.get("/admins/dashboard/unavailable_product_count"),
      ]).then(
        (res) => {
          setState({ ...state, ...formatPromiseResponse(res) })
        }
      ).catch((e) => {
        console.log("err from dash", e.response)
      })
    }, 1000);

    return () => clearTimeout(timeOut)
  }, [])

  const dashInfo = useMemo(() => {
    const info = [
      { name: "Live Products", value: state.live_products_count || 0 },
      { name: "Pending Products", value: state.pending_products_count },
      { name: "Total Products", value: state.total_products_count || 0 },
      {
        name: "Order Pending Confirmation ",
        value: state.orders_pending_confirmation_count,
      },
      { name: "Total Active Vendors", value: state.active_vendors_count || 0 },
      { name: "Total Inactive Vendors", value: state.inactive_vendors_count || 0 },
      { name: "Total Inactive Products", value: state.inactive_products_count || 0 },
    ];

    return info
  }, [state])

  return (
    <Col className="dashboardContent">
      <Row>
        {dashInfo.map((info, i) => {
          return (
            <Col md={3} className="infoBox">
              <Col className="inner">
                <Row>
                  <Col className="h-100 value">
                    <NumberField
                      value={info.value}
                      decimalScale={info.decimalScale || 0}
                    />
                  </Col>
                </Row>
                <Col className="bottom">{info.name}</Col>
              </Col>
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col md={12}>
          <Card body>
            <CardTitle>Sales for the last 7 days</CardTitle>
            <CardText>
              <LineChart
                data={state.sales_trend}
                margin={{
                  top: 5,
                  right: 5,
                  left: 5,
                  bottom: 5,
                }}
              />
            </CardText>
          </Card>
        </Col>
        <p>&nbsp;</p>
        <Col md={12}>
          <Card body>
            <CardTitle>Orders for the last 7 days</CardTitle>
            <CardText>
              <LineChart
                data={state.orders_trend}
                margin={{
                  top: 5,
                  right: 5,
                  left: 5,
                  bottom: 5,
                }}
              />
            </CardText>
          </Card>
        </Col>

      </Row>
    </Col>
  )
}

function formatPromiseResponse([
  live_products_count_response,
  pending_products_count_response,
  total_products_count_response,
  orders_pending_confirmation_count_response,
  active_vendors_response,
  inactive_vendors_response,
  inactive_products_response
]) {
  let finalRes = {
    live_products_count: 0,
    pending_products_count: 0,
    total_products: 0,
    orders_pending_confirmation: 0,
    active_vendors_count: 0,
    inactive_vendors_count: 0,
    active_products_count: 0,
    inactive_products_count: 0,
  }
  if (live_products_count_response.status === 200) {
    finalRes = {
      ...finalRes,
      live_products_count: live_products_count_response.data.data,
    }
  }
  if (pending_products_count_response.status === 200) {
    finalRes = {
      ...finalRes,
      pending_products_count: pending_products_count_response.data.data,
    }
  }
  if (total_products_count_response.status === 200) {
    finalRes = {
      ...finalRes,
      total_products_count: total_products_count_response.data.data,
    }
  }
  if (orders_pending_confirmation_count_response.status === 200) {
    finalRes = {
      ...finalRes,
      orders_pending_confirmation_count:
        orders_pending_confirmation_count_response.data.data,
    }
  }
  if (active_vendors_response.status === 200) {
    finalRes = {
      ...finalRes,
      active_vendors_count:
        active_vendors_response.data.data,
    }
  }
  if (inactive_vendors_response.status === 200) {
    finalRes = {
      ...finalRes,
      inactive_vendors_count:
        inactive_vendors_response.data.data,
    }
  }
  if (inactive_products_response.status === 200) {
    finalRes = {
      ...finalRes,
      inactive_products_count:
        inactive_products_response.data.data,
    }
  }

  return finalRes
}